const createBitmap = (
  canvas: HTMLCanvasElement
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    createImageBitmap(canvas).then((imageBitmap) => {
      const retArray = trimTransparentPixel(imageBitmap);
      if (!retArray) return reject();

      const [newCanvas, context, bounds] = retArray;

      const newWidth = bounds.right - bounds.left;
      const newHeight = bounds.bottom - bounds.top;
      const offset = 50;
      newCanvas.width = newWidth + offset;
      newCanvas.height = newHeight + offset;

      context.drawImage(
        imageBitmap,
        bounds.left,
        bounds.top,
        newWidth,
        newHeight,
        offset / 2,
        offset / 2,
        newWidth,
        newHeight
      );
      resolve(newCanvas.toDataURL());
    });
  });
};

const createBitmapWithBackground = (
  canvas: HTMLCanvasElement,
  backgroundPath: string
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const bgImage = new Image();
    bgImage.src = backgroundPath;
    bgImage.onerror = () => {
      console.error("Background image not found");
      return reject();
    };
    bgImage.onload = () => {
      createImageBitmap(canvas).then((imageBitmap) => {
        const retArray = trimTransparentPixel(imageBitmap);
        if (!retArray) return reject();

        const [newCanvas, context, bounds] = retArray;
        const { width, height } = imageBitmap;

        const newWidth = bounds.right - bounds.left;
        const newHeight = bounds.bottom - bounds.top;
        const offset = 50;
        newCanvas.width = newWidth + offset;
        newCanvas.height = newHeight + offset;

        // context.drawImage(bgImage, 0, 0, width, height);
        // context.createPattern(bgImage, "repeat");
        const pattern = context.createPattern(bgImage, "repeat");
        if (pattern) {
          context.fillStyle = pattern;
          context.fillRect(0, 0, width, height);
        }

        context.drawImage(
          imageBitmap,
          bounds.left,
          bounds.top,
          newWidth,
          newHeight,
          offset / 2,
          offset / 2,
          newWidth,
          newHeight
        );
        resolve(newCanvas.toDataURL());
      });
    };
  });
};

const trimTransparentPixel = (
  imageBitmap: ImageBitmap
):
  | [
      HTMLCanvasElement,
      CanvasRenderingContext2D,
      { top: number; left: number; right: number; bottom: number }
    ]
  | undefined => {
  const temp2 = document.createElement("canvas");
  console.log("imageBitmap", imageBitmap);
  const { width, height } = imageBitmap;
  temp2.width = width;
  temp2.height = height;
  const ctx2 = temp2.getContext("2d");
  if (!ctx2) return;

  ctx2.drawImage(imageBitmap, 0, 0);
  const { data: pixels } = ctx2.getImageData(0, 0, width, height);
  ctx2.clearRect(0, 0, width, height);

  const bounds = { top: height, left: width, right: 0, bottom: 0 };

  for (const row of Array(height).keys()) {
    for (const col of Array(width).keys()) {
      if (pixels[row * width * 4 + col * 4 + 3] !== 0) {
        if (row < bounds.top) bounds.top = row;
        if (col < bounds.left) bounds.left = col;
        if (col > bounds.right) bounds.right = col;
        if (row > bounds.bottom) bounds.bottom = row;
      }
    }
  }
  return [temp2, ctx2, bounds];
};

export { createBitmap, createBitmapWithBackground };
