import { items as base_rawitems } from "./base_game/items";
import { items as abyssal_rawitems } from "./abyssal_terrors/items";
import { characters as base_characters } from "./base_game/characters";
import { characters as abyssal_characters } from "./abyssal_terrors/characters";
import { refineCharacterItems } from "../character_manager";
import { DLC } from "../../../types/GameTypes";
import { ITEM_OBJECT } from "../../../types/AppearanceTypes";

const objects: {
  [key in DLC]: { [key: string]: ITEM_OBJECT };
} = {
  [DLC.BaseGame]: {
    ...base_rawitems,
    ...refineCharacterItems(base_characters),
  },
  [DLC.AbyssalTerrors]: {
    ...abyssal_rawitems,
    ...refineCharacterItems(abyssal_characters),
  },
};

export default objects;
